<template>
<b-card v-if="modules" no-body class="card-company-table">
    <b-table :items="modules" responsive :fields="fields" class="mb-0">

        <!-- Id -->
        <template #cell(id)="data">
            <span class="font-weight-bolder mb-12">#{{data.index+1 }}</span>
        </template>

        <!-- CreatedAt Time -->
        <template #cell(createdAt)="data">
            <span class="font-weight-bolder mb-12">{{
            data.item.createdAt | moment("DD.MM.YYYY hh:mm")
          }}</span>
        </template>

        <!-- Status -->
        <template #cell(isActive)="data">
            <b-badge pill :variant="data.item.isActive?'light-success':'light-danger'">
                {{data.item.isActive?'AKTİF':'PASİF'}}
            </b-badge>
        </template>

    </b-table>

    <!--Add Module For Package--->
    <b-modal title="Yeni Modül Ekle" ok-title="Ekle" ok-only cancel-variant="outline-secondary" v-model="addPanel" @ok="addPackageModules">
        <b-form>
            <b-form-group label="Kod" label-for="zip-code">
                <b-form-input id="zip-code" type="text" placeholder="Modül Kodu" v-model="addModuleRequest.code" />
            </b-form-group>
            <b-form-group label="Adı" label-for="zip-code">
                <b-form-input id="zip-code" type="text" placeholder="Modül Adı" v-model="addModuleRequest.name" />
            </b-form-group>
        </b-form>
    </b-modal>
</b-card>
</template>

<script>
import {
    BCard,
    BTable,
    BAvatar,
    BImg,
    BBadge,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton
} from 'bootstrap-vue'

export default {
    components: {
        BCard,
        BTable,
        BAvatar,
        BImg,
        BBadge,
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BButton
    },
    data() {
        return {
            fields: [
                { key: 'id', label: 'Ref' },
                { key: 'createdAt', label: 'Kayıt Tarihi' },
                { key: 'isActive', label: 'Durum' },
                { key: 'code', label: 'Kodu' },
                { key: 'name', label: 'Adı' },
            ],
            modules: [],
            addPanel: false,
            addModuleRequest: {
                code: '',
                name: ''
            }
        }
    },
    mounted() {
        this.$root.$on('AddButtonClick', () => {
            this.addPanel = true;
        });
    },
    created() {
        this.getData();
    },
    methods: {
        async getData() {
            var modules = await this.$http.get("Admin/Package/Modules");
            this.modules = modules.data.data;
        },
        async addPackageModules() {
            await this.$http.post("Admin/Package/AddModule", this.addModuleRequest);
            this.getData();
        }
    }
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
    .dark-layout & {
        background: $theme-dark-body-bg !important;
    }
}
</style>
